import React from 'react';

function Comic(props) {
    return (
        <div className="comic-bg">
            <img src={"https://i.imgur.com/rSFw3gJ.jpeg"} class="comic-h" alt="comic"/>
        </div> 
    );
}

export default Comic;